import React from 'react';
import { minutesToDurationString } from '../../util/dates';
import css from './SectionVitals.css';
import { getSkillLevel } from '../../util/text';

const SectionVitals = props => {
  const { duration, style, skill, spokenLanguages } = props;

  let languages;
  if (Array.isArray(spokenLanguages)) {
    languages = ['English', ...spokenLanguages.map(lang => lang.lang)];
  } else {
    languages = ['English'];
  }
  languages = languages.join(', ');
  return (
    <div className={css.container}>
      <div className={css.grid}>
        <div className={css.gridCol}>
          <div className={css.card}>
            <div className={css.cardOval}>
              <svg
                width="8"
                height="16"
                viewBox="0 0 8 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.75862 3.46875H1.24138C0.556883 3.46875 0 4.09959 0 4.875V11.125C0 11.9004 0.556883 12.5312 1.24138 12.5312H6.75862C7.44312 12.5312 8 11.9004 8 11.125V4.875C8 4.09959 7.44312 3.46875 6.75862 3.46875ZM6.11465 6.89241L4.32924 8.91494C4.25164 9.00284 4.1464 9.05225 4.03666 9.05225C3.9269 9.05225 3.82168 9.00288 3.74408 8.91494L2.4728 7.47478C2.3112 7.29175 2.3112 6.99494 2.4728 6.81187C2.6344 6.62881 2.89639 6.62881 3.05799 6.81187L4.03669 7.92059L5.52949 6.22953C5.69106 6.0465 5.95308 6.0465 6.11468 6.22953C6.27625 6.41253 6.27625 6.70934 6.11465 6.89241Z" />
                <path d="M6.0624 0.385625C6.0269 0.162406 5.85539 0 5.65517 0H2.34483C2.14461 0 1.9731 0.162406 1.9376 0.385625L1.59633 2.53125H6.40367L6.0624 0.385625Z" />
                <path d="M1.59633 13.4688L1.9376 15.6144C1.9731 15.8376 2.14461 16 2.34483 16H5.65517C5.85539 16 6.0269 15.8376 6.0624 15.6144L6.40367 13.4688H1.59633Z" />
              </svg>
            </div>
            <div className={css.cardContent}>
              <div className={css.cardTtile}>Duration</div>
              <div className={css.cardText}>{minutesToDurationString(duration)}</div>
            </div>
          </div>
        </div>

        <div className={css.gridCol}>
          <div className={css.card}>
            <div className={css.cardOval}>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 0C4.01496 0 2.4 1.53768 2.4 3.42773C2.4 5.31779 4.01496 6.85547 6 6.85547C7.98504 6.85547 9.6 5.31779 9.6 3.42773C9.6 1.53768 7.98504 0 6 0Z" />
                <path d="M10.4791 9.0948C9.49349 8.14194 8.18691 7.61719 6.8 7.61719H5.2C3.81312 7.61719 2.50651 8.14194 1.52091 9.0948C0.540133 10.043 0 11.2946 0 12.6191C0 12.8295 0.179093 13 0.4 13H11.6C11.8209 13 12 12.8295 12 12.6191C12 11.2946 11.4599 10.043 10.4791 9.0948Z" />
              </svg>
            </div>
            <div className={css.cardContent}>
              <div className={css.cardTtile}>Style</div>
              <div className={css.cardText}>{style}</div>
            </div>
          </div>
        </div>

        <div className={css.gridCol}>
          <div className={css.card}>
            <div className={css.cardOval}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.31884 11.4545C2.31884 11.7558 2.57545 12 2.89193 12H9.10807C9.42455 12 9.68116 11.7558 9.68116 11.4545V8.48485H2.31884V11.4545Z" />
                <path d="M8.77068 1.38366C8.74671 1.38366 8.72265 1.3839 8.69859 1.38442C8.10531 0.522038 7.10356 0 6.00004 0C4.89644 0 3.89469 0.522038 3.30157 1.38442C3.27748 1.38386 3.25338 1.38366 3.22936 1.38366C1.44872 1.38366 0 2.76252 0 4.45738C0 5.83632 0.966776 7.02861 2.31884 7.40603V8.72727H9.68116V7.40627C11.0331 7.02877 12 5.83608 12 4.45738C12 2.76252 10.5513 1.38366 8.77068 1.38366Z" />
              </svg>
            </div>
            <div className={css.cardContent}>
              <div className={css.cardTtile}>Skill level</div>
              <div className={css.cardText}>{getSkillLevel(skill)}</div>
            </div>
          </div>
        </div>
        <div className={css.gridCol}>
          <div className={css.card}>
            <div className={css.cardOval}>
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.25561 4.12257H11.6554V2.43928C11.6554 1.09423 10.5796 0 9.25715 0H2.39824C1.07581 0 0 1.09423 0 2.43928V4.93376C0 6.13465 0.85759 7.13563 1.98253 7.33642V9.10989C1.98253 9.28363 2.08464 9.4401 2.2408 9.50597C2.29175 9.52741 2.34516 9.53786 2.39813 9.53786C2.50762 9.53786 2.61507 9.49332 2.69475 9.40986L4.63509 7.37733V6.79915C4.63498 5.32325 5.81066 4.12257 7.25561 4.12257Z" />
                <path d="M12.1703 4.93683H7.25559C6.24676 4.93683 5.42602 5.77221 5.42602 6.79914V8.58646C5.42602 9.61339 6.24676 10.4489 7.25559 10.4489H10.518L11.8786 11.8741C11.957 11.9562 12.0628 12.0001 12.1704 12C12.2225 12 12.2751 11.9898 12.3253 11.9687C12.479 11.9039 12.5795 11.7498 12.5795 11.5788V10.4019C13.3921 10.2124 14 9.47033 14 8.58646V6.79914C14 5.77221 13.1793 4.93683 12.1703 4.93683Z" />
              </svg>
            </div>
            <div className={css.cardContent}>
              <div className={css.cardTtile}>Languages</div>
              <div className={css.cardText}>{languages}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionVitals;
