import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { calculateQuantityFromHours, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, PrimaryButton } from '../../components';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import SvgLineArrow from '../../components/SvgIcon/SvgLineArrow';
import SvgGift from '../../components/SvgIcon/SvgGift';

import css from './BookingTimeForm.css';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isInputShowing: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleToggleInput = this.handleToggleInput.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  handleToggleInput(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isInputShowing: !prevState.isInputShowing,
    }));
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      formattedPrice,
      isWithinModal,
      giftButtonLink,
      bookingButtonLink,
      ...rest
    } = this.props;
    const { isInputShowing } = this.state;

    const classes = classNames(rootClassName || css.root, className);
    const withinModalCss = classNames(isWithinModal ? css.withinModal : null);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    const isShowClasses = isInputShowing ? css.isActive : '';
    const dateInputClasses = classNames(css.inputContainer, isShowClasses);
    const sendGiftBlockClasses = classNames(css.sendGiftBlock, isShowClasses);
    const toggleInputBtnClasses = classNames(css.toggleInputBtn, isShowClasses);
    const submitButtonWrapperClasses = classNames(css.submitButtonWrapper, isShowClasses);

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            listingId,
            unitPrice,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  unitPrice,
                  startDate,
                  endDate,

                  // Calculate the quantity as hours between the booking start and booking end
                  quantity: calculateQuantityFromHours(startDate, endDate),
                  timeZone,
                }
              : null;
          // const bookingInfo = bookingData ? (
          //   <div className={css.priceBreakdownContainer}>
          //     <h3 className={css.priceBreakdownTitle}>
          //       <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
          //     </h3>
          //     <EstimatedBreakdownMaybe bookingData={bookingData} />
          //   </div>
          // ) : null;

          const submitDisabled = !bookingData;

          // const submitButtonClasses = classNames(
          //   submitButtonWrapperClassName || css.submitButtonWrapper
          // );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <div className={classNames(css.dateFlex, withinModalCss)}>
                <div className={dateInputClasses}>
                  {monthlyTimeSlots && timeZone ? (
                    <FieldDateAndTimeInput
                      {...dateInputProps}
                      className={css.bookingDates}
                      listingId={listingId}
                      bookingStartLabel={bookingStartLabel}
                      onFetchTimeSlots={onFetchTimeSlots}
                      monthlyTimeSlots={monthlyTimeSlots}
                      values={values}
                      intl={intl}
                      form={form}
                      pristine={pristine}
                      timeZone={timeZone}
                      isWithinModal={isWithinModal}
                    />
                  ) : null}
                  <div className={css.bookingAltLinks}>
                    <a href={`/book/${listingId.uuid}`}>Looking for a different date or time?</a>
                  </div>
                </div>

                <div className={css.priceBlockWrapper}>
                  <div className={css.priceBlock}>
                    <div className={css.priceTotalText}>Total price:</div>
                    <div className={css.priceTotal}>{formattedPrice}</div>
                  </div>

                  <div className={sendGiftBlockClasses}>
                    <a href={giftButtonLink} className={css.sendGiftBtn}>
                      <span>Send As Gift</span>
                      <SvgGift />
                    </a>
                  </div>

                  <div className={submitButtonWrapperClasses}>
                    <PrimaryButton type="submit" disabled={submitDisabled}>
                      <span>BOOK IT</span>
                      <SvgLineArrow />
                    </PrimaryButton>
                  </div>

                  <a href={bookingButtonLink} className={toggleInputBtnClasses}>
                    <span>Book it</span>
                    <SvgLineArrow />
                  </a>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  // submitButtonWrapperClassName: null,
  price: null,
  formattedPrice: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  // submitButtonWrapperClassName: string,
  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  formattedPrice: string,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
