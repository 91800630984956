import React from 'react';
import { string } from 'prop-types';
import css from './SectionHowItWorks.css';

const SectionHowItWorks = () => {
  return (
    <section className={css.section} id="how-it-works">
      <section className={css.memorable}>
        <div className={css.memorableContainer}>
          <h2 className={css.sectionTitle}>Creating Experiences For The World's Top Companies</h2>
          <div className={css.memorableGrid}>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/google.svg"
                alt="Google"
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/aws.svg"
                alt="Amazon web services"
                style={{ maxHeight: '55px', paddingTop: '20px' }}
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/zoom.png"
                alt="Zoom"
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/amazon.svg"
                alt="Amazon"
                style={{ maxHeight: '55px', paddingTop: '20px' }}
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/mckinsey.png"
                alt="McKinsey"
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/marqeta.svg"
                alt="Marqeta"
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/sap.svg"
                alt="SAP"
                style={{ maxHeight: '30px' }}
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/youtube.svg"
                alt="YouTube"
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/ey.svg"
                alt="EY"
                style={{ maxHeight: '53px', paddingBottom: '11px' }}
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/bcg.svg"
                alt="Boston Consulting Group"
                style={{ maxHeight: '28px' }}
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/hp.svg"
                alt="Hewlett-Packard"
                style={{ maxHeight: '40px' }}
              />
            </figure>
            <figure className={css.memorableFigure}>
              <img
                className={css.memorableImage}
                src="/static/images/companies/vmware.svg"
                alt="VMware"
              />
            </figure>
          </div>
        </div>
      </section>
    </section>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
