import React, { useState } from 'react';
import classNames from 'classnames';
import { getDistinctObjects } from '../../util/helpers';

import css from './ExpandableListSection.css';

const ExpandableListSection = ({ items, title, foldItemsOver, icon }) => {
  items = getDistinctObjects(items, 'name', true);

  const [isOpen, setIsOpen] = useState(false);
  const isOpenClasses = isOpen ? css.isOpen : '';
  const overlayClasses = classNames(css.listOverlay, isOpenClasses);
  const toggleBtnClasses = classNames(css.toggleBtn, isOpenClasses);

  const toolCount = items.length;

  let itemsToShow = items;
  if (items && !isOpen) {
    itemsToShow = items.slice(0, foldItemsOver);
  }

  return items && items.length > 0 ? (
    <div className={css.container}>
      <div className={css.card}>
        <div className={css.cardCircle}>
          <div className={css.cardCircleBg}>
            <img src={icon} alt={title} />
          </div>
        </div>
        <h4 className={css.cardTitle}>{title}</h4>

        <div className={css.cardListBox}>
          <ul className={css.cardList}>
            {itemsToShow.map(tool => (
              <li key={tool.id}>{tool.name}</li>
            ))}
          </ul>
          {toolCount >= foldItemsOver && <div aria-hidden="true" className={overlayClasses} />}
        </div>

        {toolCount >= foldItemsOver && (
          <button className={toggleBtnClasses} onClick={() => setIsOpen(!isOpen)}>
            <span>{isOpen ? 'Close' : 'See more'}</span>
            <svg
              width="6"
              height="3"
              viewBox="0 0 6 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 0L6 3H0L3 0Z" />
            </svg>
          </button>
        )}
      </div>
    </div>
  ) : null;
};

export default ExpandableListSection;
