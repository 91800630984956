import React from 'react';
import css from './SectionExplainer.css';

const SectionExplainer = props => {
  const { hostDisplayName, xpType, maxScreens } = props;
  const maxScreensText =
    maxScreens > 1 ? (
      <p>
        Connect up to {maxScreens} screens to share the experience with friends or family.{' '}
        <a href="/contact">Contact us</a> for discounted rates on additional screens.
      </p>
    ) : (
      <p>
        Looking to share the experience with friends or family? <a href="/contact">Contact us</a>{' '}
        for discounted rates on additional screens.
      </p>
    );

  switch (xpType) {
    case 'mixology':
      return (
        <div className={css.container}>
          <h3 className={css.title}>What to expect</h3>
          <p>
            You and {hostDisplayName} will meet in your home (over video chat) to shake, stir,
            taste, share stories, and delve into the world of cocktails. You will not only have a
            great time but also acquire valuable skills that will last well beyond your time
            together.
          </p>
          <strong>Private</strong>
          <p>
            This Delicious Experience is not a pre-recorded video, big Zoom gathering, or the kind
            of online course where your eyes glaze over; it is a one-of-a-kind private experience
            where you and {hostDisplayName} will work together to create something amazing together.
          </p>
          <strong>Customizable</strong>
          <p>
            After booking, you will communicate directly with {hostDisplayName} to adapt the
            experience to your tastes, interests, skill level, and alcohol preferences.
          </p>
          <strong>Virtual</strong>
          <p>
            Prior to the online cocktail class we will provide you with a Zoom link and any
            assistance you may need to connect to the experience from your laptop, tablet, or phone.
          </p>
          {maxScreensText}
        </div>
      );
    case 'wine':
      return (
        <div className={css.container}>
          <h3 className={css.title}>What to expect</h3>
          <p>
            You and {hostDisplayName} will meet in your home (over video chat) to explore the world
            of wine together.
          </p>
          <p>
            This experience can be customized to your tastes and preferences and is ideal whether
            you are new to the wine world and want to learn the fundamentals of wine appreciation,
            or an avid collector looking to delve deeper into your own wine collection.
          </p>
          <strong>Private</strong>
          <p>
            This Delicious Experience is not a pre-recorded video, big Zoom gathering, or the kind
            of online course where your eyes glaze over; it is a one-of-a-kind private experience
            where you and {hostDisplayName} will spend time together tasting, swirling, learning,
            sharing stories, and acquiring a deeper understanding and appreciation for wines.
          </p>
          <strong>Customizable</strong>
          <p>
            After booking, you will communicate directly with {hostDisplayName} to adapt the
            experience to your tastes, interests, and wine collection.
          </p>
          <strong>Virtual</strong>
          <p>
            Prior to your online wine class we will provide you with a Zoom link and any assistance
            you may need to connect to the experience from your laptop, tablet, or phone.
          </p>
          {maxScreensText}
        </div>
      );
    case 'whiskey':
    case 'sake':
      return (
        <div className={css.container}>
          <h3 className={css.title}>What to expect</h3>
          <p>
            You and {hostDisplayName} will meet in your home (over video chat) to explore the world
            of {xpType} together.
          </p>
          <p>
            This experience can be customized to your tastes and preferences and is ideal whether
            you are new to the {xpType} world and want to learn the fundamentals of {xpType}{' '}
            appreciation, or an avid collector looking to delve deeper into your own collection.
          </p>
          <strong>Private</strong>
          <p>
            This Delicious Experience is not a pre-recorded video, big Zoom gathering, or the kind
            of online course where your eyes glaze over; it is a one-of-a-kind private experience
            where you and {hostDisplayName} will spend time together tasting, learning, sharing
            stories, and acquiring a deeper understanding and appreciation for {xpType}.
          </p>
          <strong>Customizable</strong>
          <p>
            After booking, you will communicate directly with {hostDisplayName} to adapt the
            experience to your tastes, interests, and {xpType} collection.
          </p>
          <strong>Virtual</strong>
          <p>
            Prior to your online {xpType} class we will provide you with a Zoom link and any
            assistance you may need to connect to the experience from your laptop, tablet, or phone.
          </p>
          {maxScreensText}
        </div>
      );
    case 'misc':
      return (
        <div className={css.container}>
          <h3 className={css.title}>What to expect</h3>
          <p>
            You and {hostDisplayName} will meet in your home (over video chat) so that you can
            acquire valuable skills that will last well beyond your time together.
          </p>
          <strong>Private</strong>
          <p>
            This Delicious Experience is not a pre-recorded video, big Zoom gathering, or the kind
            of online course where your eyes glaze over; it is a one-of-a-kind private experience
            where you and {hostDisplayName} will work together side-by-side, learning and creating
            memorable experiences.
          </p>
          <strong>Customizable</strong>
          <p>
            After booking, you will communicate directly with {hostDisplayName} to adapt the
            experience to your particular interests.
          </p>
          <strong>Virtual</strong>
          <p>
            Prior to your online workshop we will provide you with a Zoom link and any assistance
            you may need to connect to the experience from your laptop, tablet, or phone.
          </p>
          {maxScreensText}
        </div>
      );
    default:
      return (
        <div className={css.container}>
          <h3 className={css.title}>What to expect</h3>
          <p>
            You and {hostDisplayName} will meet in your kitchen (over video chat) to cook, taste,
            share stories, and acquire valuable skills that will last well beyond your time
            together.
          </p>
          <strong>Private</strong>
          <p>
            This Delicious Experience is not a pre-recorded cooking video, big Zoom gathering, or
            the kind of online course where your eyes glaze over; it is a one-of-a-kind private
            experience where you will work together with {hostDisplayName} to create something
            amazing in your kitchen.
          </p>
          <strong>Customizable</strong>
          <p>
            After booking, you will communicate with the chef to adapt the experience and menu to
            your tastes and interests, then receive a customized ingredients list.
          </p>
          <strong>Virtual</strong>
          <p>
            Prior to your online cooking class we will provide you with a Zoom link and any
            assistance you may need to connect to the experience from your laptop, tablet, or phone.
          </p>
          {maxScreensText}
        </div>
      );
  }
};

export default SectionExplainer;
