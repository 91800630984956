import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';

import css from './EditListingExperienceForm.css';

const H1_MAX_LENGTH = 100;
const H2_MAX_LENGTH = 220;

const EditListingExperienceFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        skillLevels,
        durationValues,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const h1RequiredMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.h1Required',
      });
      const h1Message = intl.formatMessage({
        id: 'EditListingExperienceForm.h1',
      });
      const h1MaxLengthMessage = intl.formatMessage(
        { id: 'EditListingExperienceForm.maxLength' },
        {
          maxLength: H1_MAX_LENGTH,
        }
      );

      const h2Message = intl.formatMessage({
        id: 'EditListingExperienceForm.h2',
      });
      const h2MaxLengthMessage = intl.formatMessage(
        { id: 'EditListingExperienceForm.maxLength' },
        {
          maxLength: H2_MAX_LENGTH,
        }
      );

      const skillMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.skill',
      });
      const skillRequiredMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.skillRequired',
      });

      const styleMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.style',
      });
      const stylePlaceholderMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.stylePlaceholder',
      });
      const styleRequiredMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.styleRequired',
      });

      const durationMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.duration',
      });
      const durationRequiredMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.durationRequired',
      });

      const maxGuestsMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.maxGuests',
      });
      const maxGuestsRequiredMessage = intl.formatMessage({
        id: 'EditListingExperienceForm.maxGuestsRequired',
      });

      const {
        updateListingError,
        // createListingDraftError,
        showListingsError,
      } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      // const errorMessageCreateListingDraft = createListingDraftError ? (
      //   <p className={css.error}>
      //     <FormattedMessage id="EditListingExperienceForm.createListingDraftError" />
      //   </p>
      // ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingExperienceForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {/*{errorMessageCreateListingDraft}*/}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="h1"
            name="h1"
            className={css.h1}
            type="text"
            label={h1Message}
            maxLength={H1_MAX_LENGTH}
            validate={composeValidators(
              required(h1RequiredMessage),
              maxLength(h1MaxLengthMessage, H1_MAX_LENGTH)
            )}
            autoFocus
          />

          <FieldTextInput
            id="h2"
            name="h2"
            className={css.h2}
            type="text"
            label={h2Message}
            maxLength={H2_MAX_LENGTH}
            validate={composeValidators(maxLength(h2MaxLengthMessage, H2_MAX_LENGTH))}
            autoFocus
          />

          <FieldSelect
            className={css.skill}
            name="skill"
            id="skill"
            label={skillMessage}
            validate={required(skillRequiredMessage)}
          >
            <option value=""></option>
            {skillLevels.map(s => (
              <option key={s.key} value={s.key}>
                {s.label}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            id="style"
            name="style"
            className={css.style}
            placeholder={stylePlaceholderMessage}
            type="text"
            label={styleMessage}
            validate={required(styleRequiredMessage)}
            autoFocus
          />

          <FieldSelect
            className={css.duration}
            name="duration"
            id="duration"
            label={durationMessage}
            validate={required(durationRequiredMessage)}
          >
            <option value=""></option>
            {durationValues.map(d => (
              <option key={d.key} value={d.key}>
                {d.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.maxGuests}
            name="maxGuests"
            id="maxGuests"
            label={maxGuestsMessage}
            validate={required(maxGuestsRequiredMessage)}
          >
            <option value=""></option>
            {Array.from({ length: 50 }, (v, k) => (
              <option key={k + 1} value={k + 1}>
                {k + 1} guests
              </option>
            ))}
          </FieldSelect>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingExperienceFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingExperienceFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    // createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  skillLevels: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  durationValues: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingExperienceFormComponent);
