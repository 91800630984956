import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './AboutPage.css';

const AboutPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="About Us - Find out about Delicious Experiences and team behind it"
      description="Delicious Experiences was founded by the owners of a leading culinary tourism company which has introduced thousands to unforgettable culinary experiences"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Delicious Experiences was founded by the owners of a leading culinary tourism company which has introduced thousands to unforgettable culinary experiences',
        name: 'About Us - Find out about Delicious Experiences and team behind it',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            logoLinkColor="#2e2e2e"
            logoLinkMobileColor="#2e2e2e"
            topbarBelowAlways={true}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            <div className={css.contentSide} />
            <div className={css.contentMain}>
              <h1 className={css.pageTitle}>About Delicious Experiences</h1>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <p>We believe in the power of food to bring people together.</p>
            </div>

            <div className={css.contentMain}>
              <p>
                Delicious Experiences was founded by the owners of a leading culinary tour company who have introduced thousands of food-loving travelers to magical and unforgettable culinary experiences, and the people behind them. We’ve spent over a decade perfecting the art of connecting with others and creating lifelong memories over shared food and stories.
              </p>

              <p>
                Delicious Experiences was born of a desire not only to boost the hospitality and travel industries in light of the devastating COVID-19 pandemic, but also to feed that fundamental human yearning for connection. Delicious Experiences brings people together for intimate, person-to-person experiences that fill hearts and bellies, spark minds, and nourish souls.
              </p>

              <h2 className={css.sectionTitle}>The Team</h2>

              <p className={css.teamMember}>
                <img src="/static/images/team/inbal_baum.jpg" alt="Inbal Baum" />
                <strong>Inbal Baum</strong> has been passionate about food and travel for as long as she can remember. It just took some rounds for her to get there as a career. After working in human rights, practicing as an attorney in Manhattan, and finding her zen as a yoga instructor, Inbal moved to Israel to follow her heart and passion. Ten years ago she founded Israel’s leading culinary tour company, <a href="https://www.deliciousisrael.com/">Delicious Israel</a>, and now spearheads a team of 23+ passionate culinary experts to lead food tours and cooking workshops throughout Israel. Inbal has guided global political leaders, Hollywood celebs, chefs from the world’s best restaurants and many, many more to eat and hear the stories behind Israel's best foods. Inbal has written for <i>Fodor’s Guidebook</i>, <i>Travel + Leisure</i>, <i>Amex Essentials</i>, <i>Cathay Pacific</i>’s in-flight magazine, and been featured in <i>Conde Nast Traveler</i>, <i>Hemispheres Magazine</i>, <i>The Washington Post</i>, and many food TV programs.
              </p>

              <p className={css.teamMember}>
                <img src="/static/images/team/tal_ater.jpg" alt="Tal Ater" />
                <strong>Tal Ater</strong> started coding in the '80s, way before coding was cool. His most recent venture - <a href="https://dav.network/">The DAV Foundation</a> - raised $24 million towards building an open source communication standard for autonomous vehicles. An expert across subjects, Tal has authored an <i>O’Reilly</i> published book, been featured in <i>Forbes</i> and <i>The New York Times</i>, and helped build and lead numerous tech teams in many start-ups.
              </p>

              <p className={css.teamMember}>
                <img src="/static/images/team/maggie_meyers.jpg" alt="Maggie Meyers" />
                <strong>Maggie Meyers</strong> manages operations for Delicious Experiences. She brings over 15 years of hospitality experience, holding various sales, marketing, and leadership roles throughout her tenure that laid the groundwork for being a prime operations and experiences guru. Maggie has a strong passion for food and wine. Growing up on the east coast in a large family, everyone congregated in the kitchen, even if it was the smallest room in the house. This started her love of cooking and the bond that creating and sharing a meal can form. She recently acquired her Wine and Spirit Education Trust Level I certification, passing with 100% and looks forward to working towards Level II. When Maggie is not trying out a new recipe, wine, or local restaurant she is hiking, skiing, or taking in the majestic mountains of Utah.
              </p>

              <p className={css.teamMember}>
                <img src="/static/images/team/mafia_cartwright.jpg" alt="Mafia Cartwright" />
                <strong>Mafia Cartwright</strong> has over 15 years of experience working with non-profit organizations, banks and D.C. government agencies supporting and managing day-to-day operations and programs and supervising administrative staff. She also ran a freelance graphic design firm for eight years and is passionate about aesthetics and curating experiences that bring people together. In and out of her professional life, she is always finding herself in the middle of planning fun and memorable events for the people around her. She is known for throwing amazing birthday parties and events for her family and friends that include creative and tasteful designs with incredible attention to detail and structured and logistical event flow. Mafia is a true-blue foodie that enjoys tasting new flavors and making new memories by traveling and attending food festivals and local Farmers Markets. She also follows 50+ food bloggers and chefs on Instagram and TikTok to help keep her family meals fresh and exciting by trying a new recipe every week.
              </p>

              <p className={css.teamMember}>
                <img src="/static/images/team/blair_miller.jpg" alt="Blair Miller" />
                <strong>Blair Miller</strong> lives in the northern suburbs of Chicago. Traveling and cooking are her favorite hobbies, and always looks for a cooking class or food tour to attend when traveling. The kitchen is her favorite room in her house, and she’ll gladly accept any recipe challenge from any cuisine. Blair even worked part-time at Sur La Table for a few years just for the discount and cooking classes!  Aside from food and travel, Blair loves all things musical theater.
              </p>

              <p className={css.teamMember}>
                <img src="/static/images/team/akash_ahmad.jpg" alt="Akash Ahmad" />
                <strong>Akash Ahmad</strong> is the Delicious Experiences tech guru! He is a software engineer who has been coding since the age of 15. His professional career as a developer started in 2012, and since then he has focused on improving his programming skills, opting for Software Engineering as a professional degree. Akash is passionate about staying up to date with the latest technologies and trends.
              </p>

              <p className={css.teamMember}>
                <img src="/static/images/team/floilyn_verzosa.jpg" alt="Floilyn Verzosa" />
                <strong>Floilyn Verzosa</strong> is an administrator for Delicious Experiences, and loves to be a part of the team. She helps DE by constantly tracking the packages to ensure all the guests will receive everything they need in time to enjoy their virtual culinary experience. Floilyn is a licensed Electronics Engineer, and works as a Software Tester. She does not consider herself a good cook, but is a big fan of Masterchef US and Masterchef Australia. She loves trying out different foods at restaurants, and being with Delicious Experiences has started to improve her cooking skills as well! In her free time, she loves reading, traveling, and watching Netflix.
              </p>

            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
