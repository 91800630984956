import React from 'react';

export default function SvgGift({ className }) {
  return (
    <svg
      className={className}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H4C3.44772 3 3 2.55228 3 2C3 1.44772 3.44772 1 4 1C4.55228 1 5 1.44772 5 2V3ZM4 0C4.59735 0 5.13353 0.261878 5.5 0.677088C5.86647 0.261878 6.40265 0 7 0C8.10457 0 9 0.895431 9 2C9 2.36429 8.90261 2.70583 8.73244 3H9C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H2C0.895431 11 0 10.1046 0 9V5C0 3.89543 0.895431 3 2 3H2.26756C2.09739 2.70583 2 2.36429 2 2C2 0.895431 2.89543 0 4 0ZM6.0004 4H7H9C9.55228 4 10 4.44772 10 5V9C10 9.55228 9.55228 10 9 10L6.00037 10L6.0004 4ZM5.0004 4L5.00037 10L6.00037 10L2 10C1.44772 10 1 9.55228 1 9V5C1 4.44772 1.44772 4 2 4H4H5H5.0004ZM8 2C8 2.55228 7.55228 3 7 3H6V2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2Z"
      />
    </svg>
  );
}
