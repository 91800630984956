import React from 'react';
import { ResponsiveImage } from '../../components';
import css from './SectionAdditionalPhotos.css';

const SectionAdditionalPhotos = props => {
  const { listing } = props;

  if (!listing.images || listing.images.length < 3) return null;
  const images = listing.images.slice(2);
  if (
    !images[0].attributes.variants['square-small'] ||
    !images[0].attributes.variants['square-small2x']
  )
    return null;

  return (
    <div className={css.container}>
      <h4 className={css.title}>More Photos</h4>
      <div className={css.grid}>
        {images.map(image => (
          <figure className={css.gridFigure} key={image.id.uuid}>
            <ResponsiveImage
              alt=""
              image={image}
              variants={['square-small', 'square-small2x']}
              sizes="(max-width: 600px) 100vw, 50vw"
            />
          </figure>
        ))}
      </div>
    </div>
  );
};

export default SectionAdditionalPhotos;
