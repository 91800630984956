import React from 'react';

export default function SvgLineArrow({ className, rotate }) {
  return (
    <svg
      style={rotate && { transform: `rotate(${rotate}deg)` }}
      className={className}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44134 0.441143C7.23878 0.208525 6.87694 0.209937 6.6762 0.444129C6.51926 0.627221 6.51348 0.895663 6.66239 1.08534L8.07103 2.8797L1.23193 2.8797C0.955791 2.8797 0.731934 3.10355 0.731934 3.3797C0.731934 3.65584 0.955791 3.8797 1.23193 3.8797L8.07063 3.8797L6.66239 5.67354C6.51348 5.86322 6.51926 6.13166 6.6762 6.31475C6.87694 6.54895 7.23878 6.55036 7.44134 6.31774L9.89326 3.50196C9.95441 3.43173 9.95441 3.32716 9.89326 3.25693L7.44134 0.441143Z"
      />
    </svg>
  );
}
