import React from 'react';
import Slider from 'react-slick';
import css from './HomeCarousel.css';

const HomeCarousel = ({ cards }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="homeCarousel">
      <Slider {...settings}>
        {cards.map(card => {
          const { title, imageUrl } = card;
          return (
            <div className={css.hcCard} key={imageUrl}>
              <div className={css.hcCardBox}>
                <div
                  className={css.hcCardMedia}
                  style={{ backgroundImage: `url("${imageUrl}")` }}
                ></div>
                <div className={css.hcCardContent}>
                  <h3 className={css.hcCardTitle}>{title}</h3>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default HomeCarousel;
