import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage } from '../../components';
import ReactMarkdown from 'react-markdown';

import css from './SectionDescription.css';

const SectionDescription = props => {
  const { description, listing } = props;

  const hasImages = listing.images && listing.images.length > 1;
  const secondImage = hasImages ? listing.images[1] : null;
  const title = listing.attributes.title;

  return description ? (
    <div className={css.lstDescription}>
      <div className={css.lstDGrid}>
        <article className={css.lstDGridLeftColumn}>
          <h3 className={css.lstDGridTitle}>
            <FormattedMessage id="ListingPage.descriptionTitle" />
          </h3>
          <div className={css.lstDGridParagraph}>
            <ReactMarkdown source={description} />
          </div>
        </article>
        <div className={css.lstDGridRightColumn}>
          {hasImages ? (
            <ResponsiveImage
              alt={title}
              image={secondImage}
              variants={['scaled-medium', 'scaled-large']}
              sizes="(max-width: 600px) 100vw, 50vw"
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default SectionDescription;
