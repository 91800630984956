import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import css from './LandingPageFeature.css';

import SvgLineArrow from '../../components/SvgIcon/SvgLineArrow';
import deliciousBadge from '../../assets/delicious-badge.svg';
import LF_LeftImage from '../../assets/landing-feature-left.jpg';
import LF_RightImage from '../../assets/landing-feature-right.jpg';

export default function LandingPageFeature() {
  return (
    <div>
      <div className={css.artContainer}>
        <figure className={css.artFigure}>
          <img
            className={[css.artFigureImg, 'lazyload'].join(' ')}
            data-src={deliciousBadge}
            alt=""
          />
        </figure>
      </div>
      <section className={[css.ltGrid, css.gridMargin].join(' ')}>
        <div className={css.ltGridColumn}>
          <figure className={css.ltGridFigure}>
            <img
              className={[css.ltGridImg, css.girdImgLeft, 'lazyload'].join(' ')}
              data-src={LF_LeftImage}
              alt="Corporate event kit"
            />
          </figure>
        </div>
        <div className={[css.ltGridColumn, css.columnTopFirst].join(' ')}>
          <article className={css.ltGridFirstArticle}>
            <div className={css.ltGridArticleContent}>
              <h2 className={css.gridTitle}>
                <span>Corporate Connections</span>
              </h2>
              <p>
                Delicious Experiences helps companies bring remote and hybrid teams together to
                celebrate and deepen their bond, strengthen connections with clients, and improve
                employee happiness.
              </p>
              <p>
                Let us know about your goals and we will help create the perfect virtual event that
                can help foster a sense of togetherness.
              </p>
              <p>
                Whether an online cocktail-making class, cooking/baking workshop, or wine/whiskey
                tasting adventure, we can help facilitate a seamless experience, including shipping
                beautiful abundant kits to each guest's home.
              </p>
              <AnchorLink href="#contact-form" className={css.ltButton}>
                <span>Find out about corporate events</span>
                <SvgLineArrow className={css.ltButtonSvg} />
              </AnchorLink>
            </div>
          </article>
        </div>
      </section>

      <section className={css.ltGrid}>
        <div className={[css.ltGridColumn, css.order2].join(' ')}>
          <article className={css.ltGridSecondArticle}>
            <div className={css.ltGridArticleContent}>
              <h2 className={css.gridTitle}>
                <span>Best. Gift. Ever.</span>
              </h2>
              <p>You found it! The dream holiday gift for your team, partners, or clients.</p>
              <p>
                From curated artisanal snack kits through exclusive private cooking, chocolate,
                wine, or mixology workshops to fully customized and curated culinary gifts to
                showcase your brand we can work with you to create a gift that is as Delicious as
                your brand.
              </p>
              <AnchorLink href="#contact-form" className={css.ltButton}>
                <span>Explore Gifting Options</span>
                <SvgLineArrow className={css.ltButtonSvg} />
              </AnchorLink>
            </div>
          </article>
        </div>
        <div className={[css.ltGridColumn, css.order1].join(' ')}>
          <figure className={css.ltGridFigure}>
            <img
              className={[css.ltGridImg, css.girdImgRight, 'lazyload'].join(' ')}
              data-src={LF_RightImage}
              alt="Gift an experience"
            />
          </figure>
        </div>
      </section>
    </div>
  );
}
