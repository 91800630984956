import React from 'react';
import css from './ListingCallToAction.css';

import imgTopRight from '../../assets/nsArtTopRight.png';
import imgBottomLeft from '../../assets/nsArtBottomLeft.png';
import SvgLineArrow from '../../components/SvgIcon/SvgLineArrow';
import SvgGift from '../../components/SvgIcon/SvgGift';

const ListingCallToAction = props => {
  const { title, firstBtnLink, firstBtnLabel, secondBtnLink, secondBtnLabel } = props;

  return (
    <div className={css.container}>
      <img
        data-src={imgTopRight}
        alt=""
        aria-hidden="true"
        className={[css.rightArt, 'lazyload'].join(' ')}
      />
      <div className={css.grid}>
        <div className={css.content}>
          <h4>{title}</h4>
          <div className={css.grid}>
            <div className={css.btnGridCol}>
              <a href={firstBtnLink} className={css.btn}>
                <span>{firstBtnLabel}</span>
                <SvgLineArrow className={css.btnSvg} />
              </a>
            </div>
            <div className={css.btnGridCol}>
              <a href={secondBtnLink} className={css.btn}>
                <span>{secondBtnLabel}</span>
                <SvgGift className={css.btnSvg} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <img
        data-src={imgBottomLeft}
        alt=""
        aria-hidden="true"
        className={[css.leftArt, 'lazyload'].join(' ')}
      />
    </div>
  );
};

export default ListingCallToAction;
