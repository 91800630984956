import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATETIME } from '../../util/types';
import LineItemBookingPeriod from '../BookingBreakdown/LineItemBookingPeriod';
import { tz } from 'moment-timezone';

import css from './TransactionPanel.css';

const BreakDown = props => {
  const { className, rootClassName, transaction } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const timeZone = loaded && tz.guess();

  const classes = classNames(rootClassName || css.breakdownMaybe, className);

  return loaded ? (
    <div className={classes}>
      <LineItemBookingPeriod
        booking={transaction.booking}
        unitType={config.bookingUnitType}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
      />
    </div>
  ) : null;
};

export default BreakDown;
