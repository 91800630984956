import config from '../config';

/**
 *
 * @param {object} obj An object returned from CMS for an image
 * @param {string} variant Which format of image to return (e.g., 'thumbnail')
 *
 * @returns {string|undefined}
 */
export const getImageUrl = (obj, variant) => {
  if (!obj.image) return;
  if (obj.image.ext === '.svg' || !obj.image.formats || !obj.image.formats[variant]) {
    return config.cmsServerURL + obj.image.url;
  } else {
    return config.cmsServerURL + obj.image.formats[variant].url;
  }
};
