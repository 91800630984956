import React from 'react';
import { ResponsiveImage } from '../../components';
import ReactMarkdown from 'react-markdown';
import Accolade from '../../components/Accolade/Accolade';
import { getImageUrl } from '../../util/cms';

import css from './SectionBio.css';

const SectionBio = props => {
  const { hostDisplayName, hostBio, hostImage, accolades } = props;
  return (
    <div className={css.container}>
      <div className={css.grid}>
        <figure className={css.gridImage}>
          <ResponsiveImage
            alt={hostDisplayName}
            image={hostImage}
            variants={['scaled-medium', 'scaled-large']}
            sizes="(max-width: 600px) 100vw, 50vw"
          />
        </figure>
        <article className={css.gridContent}>
          <h3 className={css.title}>
            <span>Meet {hostDisplayName}</span>
          </h3>
          <ReactMarkdown source={hostBio} />
          {accolades && (
            <div className={css.accolades}>
              {accolades.map(accolade => {
                return (
                  <Accolade
                    key={accolade.id}
                    text={accolade.title}
                    imgSrc={getImageUrl(accolade, 'thumbnail')}
                  />
                );
              })}
            </div>
          )}
        </article>
      </div>
    </div>
  );
};

export default SectionBio;
