/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { InlineTextButton, Logo, NamedLink } from '../../components';

import css from './TopbarMobileMenu.css';

const TopbarMobileMenu = props => {
  const { isAuthenticated, currentPage, currentUserHasListings, currentUser, onLogout } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <a href="/">
          <Logo format="desktop" className={css.logo} alt="" />
        </a>

        <div className={css.content}>
          <div className={css.menuItems}>
            <a href="/events" className={css.navigationLink}>
              Corporate Events
            </a>
            <a href="/contact" className={css.navigationLink}>
              Contact Us
            </a>
          </div>
        </div>
      </div>
    );
  }

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <div className={css.menuItems}>
          <a href="/events" className={css.navigationLink}>
            Corporate Events
          </a>
          <a href="/contact" className={css.navigationLink}>
            Contact Us
          </a>
          <a href="/gift" className={css.navigationLink}>
            Gifts
          </a>
          <a href="/faq" className={css.navigationLink}>
            FAQ
          </a>
          <NamedLink
            className={classNames(css.inbox, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          </NamedLink>
          {/*<OwnListingLink*/}
          {/*  listing={currentUserListing}*/}
          {/*  listingFetched={currentUserListingFetched}*/}
          {/*  className={css.navigationLink}*/}
          {/*/>*/}
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
        </div>
        {/*<div className={css.footer}>*/}
        {/*  <NamedLink className={css.createNewListingLink} name="NewListingPage">*/}
        {/*    <FormattedMessage id="TopbarMobileMenu.newListingLink" />*/}
        {/*  </NamedLink>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
