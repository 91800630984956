import React from 'react';
import css from './CorporateContactForm.css';
import HubspotForm from 'react-hubspot-form';
import { bool } from 'prop-types';

const CorporateContactForm = props => {
  const { catalogRequested } = props;

  const formIntroText = catalogRequested =>
    catalogRequested ? (
      <div>
        <h3>Winter 2022 Experiences and Gifting</h3>
        <article className={css.nsArticle}>
          <p>Please fill in the form to receive our 2022 holiday catalog.</p>
        </article>
      </div>
    ) : (
      <div>
        <h3>
          Looking for a quote?
          <br />
          Want to see the full catalog?
          <br />
          Have some questions?
        </h3>
        <article className={css.nsArticle}>
          <p>
            Book a{' '}
            <a
              href="https://meetings.hubspot.com/deliciousexperiences/quick-catch-up"
              target="_blank"
              rel="noopener noreferrer"
            >
              quick call with us
            </a>{' '}
            or fill out the form below and we’ll get back to you today.
          </p>
        </article>
      </div>
    );

  return (
    <div className={css.nsSection} id="contact-form">
      <div aria-hidden="true" className={css.nsArtTopRight}>
        <img src="/static/images/gs-top-right-art.png" alt="" className="lazyload" />
      </div>

      <div className={css.nsContainer}>
        <div className={css.nsFormContent}>
          {formIntroText(catalogRequested)}
          <div>
            <HubspotForm
              region="na1"
              portalId="8269282"
              formId="0bc140be-69e7-4918-a644-b9053ba5f745"
            />
          </div>
        </div>
      </div>

      <div aria-hidden="true" className={css.nsArtBottomLeft}>
        <img src="/static/images/gs-bottom-left-art.png" alt="" className="lazyload" />
      </div>
    </div>
  );
};

CorporateContactForm.propTypes = {
  catalogRequested: bool,
};

export default CorporateContactForm;
