import React from 'react';
import css from './Accolade.css';

const Accolade = props => {
  const { text, imgSrc } = props;
  return (
    <div className={css.widget}>
      <div className={css.circle}>
        <img data-src={imgSrc || '/static/images/accolade.png'} alt={text} className="lazyload" />
      </div>
      {text && <div className={css.text}>{text}</div>}
    </div>
  );
};

export default Accolade;
