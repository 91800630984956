import React, { useEffect } from 'react';
import { arrayOf, bool, func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageTopbarBelow, manageRequestCatalog } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SectionHero,
  SectionHowItWorks,
} from '../../components';
import { TopbarContainer } from '../../containers';
import HomeCarousel from '../../components/HomeCarousel/HomeCarousel';

import facebookImage from '../../assets/facebook-1200x630.jpg';
import twitterImage from '../../assets/twitter-600x314.jpg';
import css from './LandingPage.css';
// import { getFeaturedListings } from './LandingPage.duck';
// import { getListingsById } from '../../ducks/marketplaceData.duck';
import LandingPageFeature from '../LandingPageFeature/LandingPageFeature';
import CorporateContactForm from '../../components/NewsletterForm/CorporateContactForm';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    onManageTopbarBelow,
    topbarBelow,
    catalogRequested,
    onManageRequestCatalog,
  } = props;

  // Add an "unmount" effect to make sure topbar is no longer marked as below
  useEffect(() => {
    return () => {
      onManageTopbarBelow(false);
    };
  }, [onManageTopbarBelow]);

  const heroFeaturedListings = [
    {
      title: 'The Cocktail Quest',
      imageUrl: '/static/images/featured_listings/vance.jpg',
    },
    {
      title: 'A Nailed It Style Decorating Competition',
      imageUrl: '/static/images/featured_listings/lasheeda.jpg',
    },
    {
      title: 'The Joy of Wine Tasting and Pairing',
      imageUrl: '/static/images/featured_listings/etheliya.jpg',
    },
    {
      title: 'It Takes a Gingerbread Village',
      imageUrl: '/static/images/featured_listings/georgia_gingerbread.jpg',
    },
    {
      title: 'Tasting Through the World of Whiskey',
      imageUrl: '/static/images/featured_listings/tracie.jpg',
    },
    {
      title: 'Bean-to-Bar: A Chocolate Tasting Journey',
      imageUrl: '/static/images/featured_listings/ruth_kennison.jpg',
    },
    {
      title: 'Spice Up Your Life With Sichuan Cuisine',
      imageUrl: '/static/images/featured_listings/wenwen.jpg',
    },
    {
      title: 'Fresh Pasta Making',
      imageUrl: '/static/images/featured_listings/cooking_touch.jpg',
    },
  ];

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              onManageTopbarBelow={onManageTopbarBelow}
              topbarBelow={topbarBelow}
              onManageRequestCatalog={onManageRequestCatalog}
              catalogRequested={catalogRequested}
              featuredListings={heroFeaturedListings}
            />
          </div>

          <div className={css.mCarousel}>
            <div className={css.mCarouselContainer}>
              <HomeCarousel cards={heroFeaturedListings} />
            </div>
          </div>

          <SectionHowItWorks />

          {/*<div className={css.listingContainer} id="experiences">*/}
          {/*  <div className={css.listingGrid}>*/}
          {/*    {featuredListings.map(listing => {*/}
          {/*      return <ExperienceListingCardComponent key={listing.id.uuid} listing={listing} />;*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</div>*/}

          <LandingPageFeature />
          <CorporateContactForm catalogRequested={catalogRequested} />

          {props.children}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  topbarBelow: bool,
  featuredListings: arrayOf(object).isRequired,
  catalogRequested: bool.isRequired,

  onManageTopbarBelow: func.isRequired,
  onManageRequestCatalog: func.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onManageTopbarBelow: topbarBelow => dispatch(manageTopbarBelow(topbarBelow)),
  onManageRequestCatalog: requestCatalog => dispatch(manageRequestCatalog(requestCatalog)),
});

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  // const { featuredListingIds } = state.LandingPage;
  const { topbarBelow, catalogRequested } = state.UI;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    featuredListings: [],
    topbarBelow,
    catalogRequested,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

// LandingPage.loadData = getFeaturedListings;

export default LandingPage;
