import React from 'react';
import ReactMarkdown from 'react-markdown';
import css from './SectionExtraInfo.css';

const SectionExtraInfo = props => {
  const { convincer } = props;
  if (!convincer || !convincer[0]) return null;
  const { content } = convincer[0];

  return (
    <div className={css.container}>
      <article className={css.article}>
        <h3 className={css.title}>This experience is perfect for you if ...</h3>
        <div className={css.list}>
          <ReactMarkdown source={content} />
        </div>
      </article>
    </div>
  );
};

export default SectionExtraInfo;
