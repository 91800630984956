import React from 'react';
import { bool, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Logo, NamedLink } from '../../components';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const { className, rootClassName, intl, logoLinkColor, topbarBelow } = props;

  const classes = classNames(rootClassName || css.root, className, topbarBelow && css.topbarLow);

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage" style={{ color: logoLinkColor }}>
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentPage: null,
  topbarBelow: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentPage: string,
  intl: intlShape.isRequired,
  topbarBelow: bool,
};

export default TopbarDesktop;
