import React from 'react';
import css from './SectionMenu.css';

const SectionMenu = props => {
  const { menu } = props;
  return menu && menu.length > 0 ? (
    <div className={css.container}>
      <h4 className={css.title}>Suggested Menu</h4>
      <p>This menu can be completely customized with your host after booking</p>
      {menu.map(menuItem => (
        <div className={css.card} key={menuItem.id}>
          <div aria-hidden="true" className={css.cardCircle}>
            <span></span>
          </div>
          <h6 className={css.title}>{menuItem.title}</h6>
          <p>{menuItem.description}</p>
        </div>
      ))}
    </div>
  ) : null;
};

export default SectionMenu;
