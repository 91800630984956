/**
 * Return an array of distinct objects removing ones that have the same value in a certain key
 *
 * @param {object[]}  arr     Input array
 * @param {string}    keyName Key in each object to check for uniqueness
 * @param {boolean}   sort    Should result be sorted by the same key (default: true)
 * @returns {array}
 */
export const getDistinctObjects = (arr, keyName, sort = true) => {
  if (!arr || !arr.length) return [];

  const existingKeys = [];
  arr = arr
    // remove items missing a value in `keyName` (or with a false-like value)
    .filter(o => o[keyName])
    // reduce to only distinct items
    .reduce((reducedList, item) => {
      if (!existingKeys.includes(item[keyName])) {
        existingKeys.push(item[keyName]);
        return [...reducedList, item];
      } else {
        return reducedList;
      }
    }, []);

  if (sort) {
    arr.sort((a, b) => (a[keyName].toUpperCase() < b[keyName].toUpperCase() ? -1 : 1));
  }
  return arr;
};
