import React from 'react';
import {
  AboutPage,
  // AuthenticationPage,
  // CheckoutPage,
  // ContactDetailsPage,
  // EditListingPage,
  EmailVerificationPage,
  // InboxPage,
  LandingPage,
  ListingPage,
  NotFoundPage,
  // PasswordChangePage,
  // PasswordRecoveryPage,
  PasswordResetPage,
  // StripePayoutPage,
  // PaymentMethodsPage,
  // ProfilePage,
  // ProfileSettingsPage,
  // SearchPage,
  TransactionPage,
  FaqPage,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

export const ACCOUNT_SETTINGS_PAGES = [
  // 'ContactDetailsPage',
  // 'PasswordChangePage',
  // 'StripePayoutPage',
  // 'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
// const draftId = '00000000-0000-0000-0000-000000000000';
// const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
      loadData: LandingPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    // {
    //   path: '/s',
    //   name: 'SearchPage',
    //   component: props => <SearchPage {...props} />,
    //   loadData: SearchPage.loadData,
    // },
    // {
    //   path: '/s/filters',
    //   name: 'SearchFiltersPage',
    //   component: props => <SearchPage {...props} tab="filters" />,
    //   loadData: SearchPage.loadData,
    // },
    // {
    //   path: '/s/listings',
    //   name: 'SearchListingsPage',
    //   component: props => <SearchPage {...props} tab="listings" />,
    //   loadData: SearchPage.loadData,
    // },
    // {
    //   path: '/s/map',
    //   name: 'SearchMapPage',
    //   component: props => <SearchPage {...props} tab="map" />,
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    // {
    //   path: '/l/:slug/:id/checkout',
    //   name: 'CheckoutPage',
    //   auth: true,
    //   component: props => <CheckoutPage {...props} />,
    //   setInitialValues: CheckoutPage.setInitialValues,
    // },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    // {
    //   path: '/l/new',
    //   name: 'NewListingPage',
    //   auth: true,
    //   component: () => (
    //     <NamedRedirect
    //       name="EditListingPage"
    //       params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
    //     />
    //   ),
    // },
    // {
    //   path: '/l/:slug/:id/:type/:tab',
    //   name: 'EditListingPage',
    //   auth: true,
    //   component: props => <EditListingPage {...props} allowOnlyOneListing />,
    //   loadData: EditListingPage.loadData,
    // },
    // {
    //   path: '/l/:slug/:id/:type/:tab/:returnURLType',
    //   name: 'EditListingStripeOnboardingPage',
    //   auth: true,
    //   component: props => <EditListingPage {...props} />,
    //   loadData: EditListingPage.loadData,
    // },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    // {
    //   path: '/u/:id',
    //   name: 'ProfilePage',
    //   component: props => <ProfilePage {...props} />,
    //   loadData: ProfilePage.loadData,
    // },
    // {
    //   path: '/profile-settings',
    //   name: 'ProfileSettingsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <ProfileSettingsPage {...props} />,
    // },
    // {
    //   path: '/login',
    //   name: 'LoginPage',
    //   component: props => <AuthenticationPage {...props} tab="login" />,
    // },
    // {
    //   path: '/signup',
    //   name: 'SignupPage',
    //   component: props => <AuthenticationPage {...props} tab="signup" />,
    // },
    // {
    //   path: '/recover-password',
    //   name: 'PasswordRecoveryPage',
    //   component: props => <PasswordRecoveryPage {...props} />,
    // },
    // {
    //   path: '/inbox',
    //   name: 'InboxBasePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    // },
    // {
    //   path: '/inbox/:tab',
    //   name: 'InboxPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <InboxPage {...props} />,
    //   loadData: InboxPage.loadData,
    // },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    // {
    //   path: '/account',
    //   name: 'AccountSettingsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: () => <NamedRedirect name="ContactDetailsPage" />,
    // },
    // {
    //   path: '/account/contact-details',
    //   name: 'ContactDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <ContactDetailsPage {...props} />,
    //   loadData: ContactDetailsPage.loadData,
    // },
    // {
    //   path: '/account/change-password',
    //   name: 'PasswordChangePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <PasswordChangePage {...props} />,
    // },
    // {
    //   path: '/account/payments',
    //   name: 'StripePayoutPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <StripePayoutPage {...props} />,
    //   loadData: StripePayoutPage.loadData,
    // },
    // {
    //   path: '/account/payments/:returnURLType',
    //   name: 'StripePayoutOnboardingPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <StripePayoutPage {...props} />,
    //   loadData: StripePayoutPage.loadData,
    // },
    // {
    //   path: '/account/payment-methods',
    //   name: 'PaymentMethodsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <PaymentMethodsPage {...props} />,
    //   loadData: PaymentMethodsPage.loadData,
    // },
    {
      path: '/faq',
      name: 'FaqPage',
      component: props => <FaqPage {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
