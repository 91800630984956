import React from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  ensureUser,
  ensureCurrentUser,
  userDisplayNameAsString,
  userAbbreviatedName,
} from '../../util/data';
import { ResponsiveImage, IconBannedUser, NamedLink } from '../../components/';

import css from './Avatar.css';

// Responsive image sizes hint
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

const AVATAR_IMAGE_VARIANTS = [
  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

export const AvatarComponent = props => {
  const {
    rootClassName,
    className,
    initialsClassName,
    user,
    renderSizes,
    disableProfileLink,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className, 'topbar_avatar');

  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const isBannedUser = avatarUser.attributes.banned;
  const isDeletedUser = avatarUser.attributes.deleted;

  const bannedUserDisplayName = intl.formatMessage({
    id: 'Avatar.bannedUserDisplayName',
  });

  const deletedUserDisplayName = intl.formatMessage({
    id: 'Avatar.deletedUserDisplayName',
  });

  const defaultUserDisplayName = isBannedUser
    ? bannedUserDisplayName
    : isDeletedUser
    ? deletedUserDisplayName
    : '';

  const defaultUserAbbreviatedName = '';

  const displayName = userDisplayNameAsString(avatarUser, defaultUserDisplayName);
  const abbreviatedName = userAbbreviatedName(avatarUser, defaultUserAbbreviatedName);
  const rootProps = { className: classes, title: displayName };
  const linkProps = avatarUser.id
    ? { name: 'ProfilePage', params: { id: avatarUser.id.uuid } }
    : { name: 'ProfileBasePage' };
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  const profileLinkEnabled = !disableProfileLink;

  const classForInitials = initialsClassName || css.initials;

  if (isBannedUser || isDeletedUser) {
    return (
      <div {...rootProps}>
        <IconBannedUser className={css.bannedUserIcon} />
      </div>
    );
  } else if (hasProfileImage && profileLinkEnabled) {
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </NamedLink>
    );
  } else if (hasProfileImage) {
    return (
      <div {...rootProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={avatarUser.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </div>
    );
  } else if (profileLinkEnabled) {
    // Placeholder avatar (initials)
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <span className={classForInitials}>{abbreviatedName}</span>
      </NamedLink>
    );
  } else {
    // Placeholder avatar (initials)
    return (
      <div {...rootProps}>
        {abbreviatedName ? (
          <span className={classForInitials}>{abbreviatedName}</span>
        ) : (
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.4727 10.2678C11.3687 10.4539 11.2507 10.6315 11.1158 10.8146L11.1157 10.8146L11.1099 10.8228C10.9185 11.0941 10.6954 11.3429 10.4581 11.5907C10.2608 11.7967 10.0354 12.0026 9.81232 12.1773C8.69924 13.0454 7.36352 13.5 5.98575 13.5C4.61119 13.5 3.27848 13.0475 2.16696 12.1834C1.92311 11.9765 1.70309 11.7888 1.51342 11.5907L1.50818 11.5852L1.50277 11.5799C1.26655 11.3478 1.05784 11.101 0.861655 10.8229L0.861669 10.8229L0.859211 10.8195C0.740343 10.654 0.618001 10.4751 0.517247 10.3001C0.594074 10.123 0.690304 9.94041 0.793856 9.7842L0.793944 9.78426L0.799455 9.77557C1.4077 8.8157 2.37578 8.18777 3.44248 8.0346L3.45815 8.03235L3.46952 8.02997C3.47093 8.03011 3.47339 8.03047 3.4769 8.03139C3.48158 8.03261 3.48679 8.03451 3.49197 8.03707C3.49716 8.03964 3.50119 8.0423 3.50389 8.04442L3.50695 8.04679C4.23358 8.60688 5.10108 8.89681 6.00094 8.89681C6.9008 8.89681 7.7683 8.60688 8.49493 8.04679L8.49494 8.04681L8.49613 8.04587C8.49779 8.04505 8.50303 8.04266 8.51304 8.04031C8.52892 8.03658 8.5495 8.03478 8.57144 8.03635C9.63029 8.19207 10.5815 8.81712 11.2051 9.77977L11.2051 9.77979L11.208 9.7842C11.3115 9.94031 11.4016 10.1014 11.4727 10.2678ZM3.02021 3.63493C3.02021 1.8904 4.38211 0.5 6.00091 0.5C7.61972 0.5 8.98162 1.8904 8.98162 3.63493C8.98162 5.37945 7.61972 6.76985 6.00091 6.76985C4.38211 6.76985 3.02021 5.37945 3.02021 3.63493Z" />
          </svg>
        )}
      </div>
    );
  }
};

AvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  initialsClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
};

AvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  initialsClassName: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarMedium = props => (
  <Avatar rootClassName={css.mediumAvatar} renderSizes={AVATAR_SIZES_MEDIUM} {...props} />
);
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = props => (
  <Avatar rootClassName={css.largeAvatar} renderSizes={AVATAR_SIZES_LARGE} {...props} />
);
AvatarLarge.displayName = 'AvatarLarge';
