import React from 'react';
import ReactDOM from 'react-dom';
import css from './BasicModal.css';

const BasicModal = props => {
  const { className, closeModalFn, children } = props;

  return ReactDOM.createPortal(
    <div className={className}>
      <div className={css.modalWrapper}>
        <div onClick={closeModalFn} className={css.modalBackdrop} />
        <div className={css.modalBox}>{children}</div>
      </div>
    </div>,
    document.getElementById('portal-root')
  );
};

export default BasicModal;
