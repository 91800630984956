import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Tooltip } from 'react-tippy';
import { useInView } from 'react-intersection-observer';
import listingHeroArt from '../../assets/delicious-badge.svg';
import SvgLineArrow from '../../components/SvgIcon/SvgLineArrow';
import css from './SectionHero.css';

const SectionHero = props => {
  const { title, listing, hostDisplayName, onManageTopbarBelow, topbarBelow, uuid } = props;

  const { publicData, metadata } = listing.attributes;
  const subheader = publicData.h2 || publicData.h1;
  const hasImages = listing.images && listing.images.length > 0;
  const heroImage = hasImages ? listing.images[0].attributes.variants['scaled-xlarge'] : null;
  const dNote = metadata && metadata.donationNote;

  const [ref, inView] = useInView({
    threshold: 0,
  });

  if (topbarBelow === inView) {
    onManageTopbarBelow(!inView);
  }

  return (
    <div className={css.lstHero} ref={ref}>
      <div className={css.lstHeroArtContainer}>
        <figure className={css.lstHeroArtFigure}>
          <img src={listingHeroArt} alt="" />
        </figure>
      </div>

      <div className={css.lstHeroContent}>
        <div className={css.lstHeroContentColumn}>
          <div className={css.heroLeftContent}>
            <article className={css.heroLeftContentArticle}>
              <h1 className={css.lstHeroTitle}>{title}</h1>
              <div className={css.heroHost}>{hostDisplayName}</div>
              <h2 className={css.lstHeroSubTitle}>{subheader}</h2>
              <ul className={css.heroPills}>
                <li>
                  <a href={`/events?xpId=${uuid}`}>Great for Teams or Clients</a>
                </li>
                <Tooltip
                  // options
                  title={
                    'We specialize in creating memorable online experiences. Each of our chefs has been carefully vetted and trained to provide incredible, entertaining, and memorable online experiences.'
                  }
                  position="top"
                  trigger="click"
                  arrow={true}
                  theme="light"
                >
                  <li>Virtual</li>
                </Tooltip>
              </ul>
              <AnchorLink href="#experience-description" className={css.lstHeroBtn}>
                <span>Tell me more</span>
                <SvgLineArrow className={css.lstHeroBtnSvg} />
              </AnchorLink>
            </article>
            {dNote && (
              <div className={css.dNote}>
                <div className={css.dNoteBox}>
                  <div className={css.dNoteCircle}>
                    <img src="/static/images/icon-donation.svg" alt="donation" />
                  </div>
                  <div className={css.dNoteText}>{dNote}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={css.lstHeroContentColumn}>
          <div
            className={css.heroCardMedia}
            style={{ backgroundImage: `url("${heroImage && heroImage.url}")` }}
          >
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
