/*
 * Marketplace specific configuration.
 */

export const skillLevels = [
  { key: 'all', label: 'Everyone' },
  { key: 'casual', label: 'Casual Cook' },
  { key: 'pro', label: 'Seasoned Pro' },
  { key: 'other', label: 'Other' },
];

export const durationValues = [
  { key: '30', label: '30 minutes' },
  { key: '45', label: '45 minutes' },
  { key: '60', label: '1 hour' },
  { key: '75', label: '1 hour 15 minutes' },
  { key: '90', label: '1.5 hours' },
  { key: '105', label: '1 hour 45 minutes' },
  { key: '120', label: '2 hours' },
  { key: '150', label: '2.5 hours' },
  { key: '180', label: '3 hours' },
  { key: '210', label: '3.5 hours' },
  { key: '240', label: '4 hours' },
  { key: '270', label: '4.5 hours' },
  { key: '300', label: '5 hours' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
