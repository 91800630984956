import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingExperienceForm } from '../../forms';
import config from '../../config';

import css from './EditListingExperiencePanel.css';

const EditListingExperiencePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingExperiencePanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingExperiencePanel.editExprienceTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingExperiencePanel.createExperienceTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingExperienceForm
        className={css.form}
        initialValues={{
          h1: publicData.h1,
          h2: publicData.h2,
          skill: publicData.skill,
          duration: publicData.duration,
          maxGuests: publicData.maxGuests,
          style: publicData.style,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { h1, h2, skill, duration, maxGuests, style } = values;
          const updateValues = {
            publicData: {
              h1: h1.trim(),
              h2: h2.trim(),
              skill,
              duration: parseInt(duration),
              maxGuests: parseInt(maxGuests),
              style: style.trim(),
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        skillLevels={config.custom.skillLevels}
        durationValues={config.custom.durationValues}
      />
    </div>
  );
};

EditListingExperiencePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingExperiencePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingExperiencePanel;
