// ================ Action types ================ //

export const FEATURED_LISTINGS_REQUEST = 'app/LandingPage/FEATURED_LISTINGS_REQUEST';
export const FEATURED_LISTINGS_SUCCESS = 'app/LandingPage/FEATURED_LISTINGS_SUCCESS';
export const FEATURED_LISTINGS_ERROR = 'app/LandingPage/FEATURED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  featuredListingIds: [],
  getFeaturedListingInProgress: false,
  getFeaturedListingError: null,
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FEATURED_LISTINGS_REQUEST:
      return {
        ...state,
        getFeaturedListingInProgress: true,
        getFeaturedListingError: null,
      };
    case FEATURED_LISTINGS_SUCCESS:
      return {
        ...state,
        featuredListingIds: resultIds(payload.data),
        getFeaturedListingInProgress: false,
      };
    case FEATURED_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, getFeaturedListingInProgress: false, searchListingsError: payload };
    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const getFeaturedListingsRequest = () => ({
  type: FEATURED_LISTINGS_REQUEST,
});

export const getFeaturedListingsSuccess = response => ({
  type: FEATURED_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const getFeaturedListingsError = e => ({
  type: FEATURED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const getFeaturedListings = () => (dispatch, getState, sdk) => {
  dispatch(getFeaturedListingsRequest());

  return Promise.resolve({});
};
