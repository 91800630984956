import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './FaqPage.css';

const FaqPage = () => {
  return (
    <StaticPage
      className={css.root}
      title="Delicious Experiences Frequently Asked Questions"
      description="Frequently Asked Questions about Delicious Experiences online culinary events"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FaqPage',
        description:
          'Frequently Asked Questions about Delicious Experiences online culinary events',
        name: 'FAQ',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="FaqPage"
            logoLinkColor="#2e2e2e"
            logoLinkMobileColor="#2e2e2e"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <section className={css.container}>
            <h1 className={css.title}>Frequently Asked Questions</h1>
            <article className={css.article}>
              <ol>
                <li>
                  <h2>What are Delicious Experiences?</h2>
                  <p>
                    Delicious Experiences are unique, private, live virtual culinary experiences led
                    by the world’s top culinary experts. You can schedule your Delicious Experiences
                    through slot and a given class with a chef. Your host will walk you through
                    exactly what you need to do, step-by-step, in an interactive way! No two classes
                    are alike, as each is uniquely curated by the culinary expert.
                  </p>
                  <p>
                    Our experiences are not pre-recorded distance learning classes, or group classes
                    where you sit back and watch someone else cook. In this private class, you (and
                    your family/friends) are the main star(s) of the kitchen, as you cook live and
                    “in-person” alongside your expert through video conferencing.
                  </p>
                </li>

                <li>
                  <h2>What types of Experiences are offered?</h2>
                  <p>
                    Everything under the culinary sun! If it has to do with food, we’re here for it.
                    Our Delicious experts teach cooking classes, wine tasting workshops, mixology
                    lessons, baking classes, food photography, food writing, virtual food tours, and
                    other food appreciation classes.
                  </p>
                  <p>
                    Each of the offerings have been curated to teach the expert’s specialty and can
                    range from learning a certain type of regional cuisine to skills training or
                    home-cook basics like learning about baking doughs or the wines of a region.
                  </p>
                  <p>
                    <a href="/contact">Contact us to customize your experience</a>
                  </p>
                </li>

                <li>
                  <h2>Who are the Chefs and Culinary Experts?</h2>
                  <p>
                    Our seasoned experts come from around the world -- the award-winning chefs,
                    mixologists and gurus whose restaurants/bars you would be visiting when
                    traveling to their city, or the cooking schools in destinations you would love
                    to be visiting. Our chefs and experts are highly talented and globally-acclaimed
                    experts who know the best way to offer their knowledge and skill is a private,
                    up close and personal session.
                  </p>
                  <p>
                    Our experts have won just about every culinary accolade imaginable - James
                    Beard, Michelin Star, Top 50 in the World, New York Times bestsellers, and the
                    list goes on and on. You will be learning from the best of the best.
                  </p>
                </li>

                <li>
                  <h2>Who are your classes meant for?</h2>
                  <p>
                    Anyone who loves to have fun and learn new things! We are thrilled to offer a
                    variety of classes for all skill levels - kitchen first-timers, family-friendly
                    options for fun with little ones, ambitious home cooks who want to mix things up
                    a bit, and up to skilled chefs looking to perfect a dish or cuisine. Whether you
                    are up for a fun date night, team-building exercise, celebrating an occasion, or
                    looking for a fun activity to spice up your weekend, our Delicious Experiences
                    offer something for everyone.
                  </p>
                </li>

                <li>
                  <h2>Do you offer gift certificates?</h2>
                  <p>
                    We sure do! Send an email to{' '}
                    <a href="mailto:info@deliciousexperiences.com">info@deliciousexperiences.com</a>{' '}
                    to purchase a gift certificate.
                  </p>
                </li>

                <li>
                  <h2>Can I book an experience for someone else?</h2>
                  <p>
                    Yes! Simply purchase a gift certificate for a specific experience/chef or a
                    blanket amount that can be sent directly to a recipient of your choice. Your
                    lucky gift recipient will then be able to coordinate directly with the chef to
                    find the most appropriate time or select a workshop of their choosing.
                  </p>
                </li>

                <li>
                  <h2>
                    A dish I want to make isn’t in any of the classes / I want to book a specific
                    chef, but not their menu offering / I want to learn a specific skill. Do you
                    offer custom options?
                  </h2>
                  <p>
                    Yes! Our chefs always love to mix it up and are excited to do custom menus. They
                    can even help you recreate your favorite restaurant dish or something you tasted
                    on your travels.
                  </p>
                  <p>
                    Please <a href="/contact">contact us</a> and we will be happy to try and
                    accommodate your requested experiences through our unparalleled access to top
                    chefs.
                  </p>
                  <p>
                    <a href="/">Search our experiences here</a>.
                  </p>
                </li>

                <li>
                  <h2>Are all of the classes private?</h2>
                  <p>
                    Yes! Just you and yours. Delicious Experiences are not open group sessions.
                    Depending on the experience, these are suitable for groups of 1-6, and are
                    always private - just you and your friends/family/colleagues and the Delicious
                    expert.
                  </p>
                </li>

                <li>
                  <h2>Are these interactive?</h2>
                  <p>
                    Yes! This is the most important part of Delicious Experiences - you should get
                    ready to roll up your sleeves and join the culinary adventure. Unlike
                    pre-recorded online video class options or large, open-group Zoom chats, our
                    private classes are designed to maximize interaction and value by having you
                    learning the skills first-hand from chefs. You’ll receive valuable instruction
                    by having the chef/instructor guide you personally through your experience,
                    leaving no guesswork for you.
                  </p>
                </li>

                <li>
                  <h2>How long are classes?</h2>
                  <p>
                    The experts have set an approximate duration (which can be seen on each offering
                    page) ranging from 1 hour to 3+ hours. The actual length of time may differ
                    based on skill level and how prepared you come to the class. We strongly
                    recommend carefully reading and following what advanced preparation you should
                    do, like measuring ingredients in advance. This will clear up more time for the
                    fun parts!
                  </p>
                </li>

                <li>
                  <h2>Sounds fun! How do I book?</h2>
                  <p>
                    At the bottom of each experience page you’ll see a booking form. Click the date
                    to see when your instructor is available and choose the time that works for you.
                    Finish the booking process and we’ll notify the chef of the booking and you will
                    receive a confirmation email. Note that your card will not be charged until the
                    chef has confirmed your booking.
                  </p>
                </li>

                <li>
                  <h2>I am not sure I want to do this in my own kitchen</h2>
                  <p>
                    There are a few fantastic benefits of doing these in your home. First, is that
                    when you learn how to use your own kitchen space, you will have an easier time
                    recreating these dishes in the future. Second, and especially in our current
                    situation, using your own space means that you can rest assured that it will be
                    sanitary according to your standards, and you won’t need to share the food space
                    with unfamiliar people.
                  </p>
                </li>

                <li>
                  <h2>
                    I’m missing an ingredient (or more) or can’t access it near me. Can I still do
                    the class?
                  </h2>
                  <p>
                    Of course you can! Each offering lists the necessary ingredients, taking into
                    account general availability, but our skilled chefs have years of experience
                    improvising and substituting, and are happy to work with what you have. Please
                    notify them in advance so they can maximize your Delicious Experience.
                  </p>
                </li>

                <li>
                  <h2>How do I contact my chef before the experience?</h2>
                  <p>
                    Once your Delicious expert has confirmed your booking, you will be able to chat
                    with your host through the Delicious Experiences platform.
                  </p>
                </li>

                <li>
                  <h2>How do I connect with my chef/expert (technology-wise)?</h2>
                  <p>
                    Your booking confirmation email will provide you with your web conference
                    details. Please be in touch with our Delicious Experiences Concierge if you have
                    any questions.
                  </p>
                </li>

                <li>
                  <h2>What technology do I need?</h2>
                  <p>
                    Delicious Experiences are designed to take place in the comfort of your own
                    home, and you don’t need to be a tech guru to enjoy them. The only technology
                    that you need to enjoy this experience is a laptop (or phone, though we
                    recommend laptop for better visibility) with audiovisual capabilities so you can
                    see and hear your chef and they can see and hear you, and a solid internet
                    connection. Any additional equipment you have such as a tripod or speakers are
                    great.
                  </p>
                </li>

                <li>
                  <h2>How much does it cost? Is it per person?</h2>
                  <p>
                    Delicious Experiences prices are set by the expert and each session’s cost is
                    listed on its Delicious offering page. Private experiences are a flat rate, not
                    per person, for groups of up to 6. If your group will be more than 6 people,
                    there may be a supplemental fee.
                  </p>
                </li>

                <li>
                  <h2>What is included in a Delicious Experience?</h2>
                  <p>Your Delicious Experience includes:</p>
                  <ul>
                    <li>
                      1+ hours of direct time with an expert chef (this can be 1:1, or you can have
                      a few people in your home)
                    </li>
                    <li>Instructions and recipes for the listed skills/dishes</li>
                    <li>
                      Time to answer any kitchen questions you might have! Think “office hours” with
                      a chef.
                    </li>
                    <li>Detailed ingredient list for pre-experience shopping and prep</li>
                    <li>Some Delicious Experiences include recipes for recreating the dishes</li>
                    <li>
                      Any other resources at the chef/instructor’s discretion, such as pre-recorded
                      videos, additional reading or online materials{' '}
                    </li>
                    <li>
                      Access to the Delicious Experiences Concierge team for questions before,
                      during and after your experience{' '}
                    </li>
                  </ul>
                  <p>**The listed price does not include ingredients or equipment**</p>
                </li>

                <li>
                  <h2>What is your cancellation/refund policy? What if I need to reschedule?</h2>
                  <p>
                    Since all experiences are private and do not run unless you are there, we have a
                    strict cancellation policy of 72 hour advance notice. Any cancellation made
                    within 72 hours will not be refundable. With enough notice (prior to 72 hours),
                    your experience can be rescheduled with your chef. You may transfer your
                    experience to another guest, just please let us know so that we can change
                    notification details.
                  </p>
                </li>

                <li>
                  <h2>Will you provide an ingredient list?</h2>
                  <p>
                    Absolutely! Once you book you will receive a detailed ingredients list, so
                    you’ll know exactly what you’ll need. You might even find that you have many of
                    the ingredients already in your house for a given class. Our chefs are trained
                    to improvise as needed, but we strongly recommend coming prepared as per the
                    chef’s instructions to maximize your Delicious Experience.
                  </p>
                </li>

                <li>
                  <h2>Do I get to keep the recipes?</h2>
                  <p>
                    Yes! Our chefs are encouraged to provide you with detailed recipes to use after
                    your workshop, although materials provided are at the discretion of the chef.
                  </p>
                </li>

                <li>
                  <h2>Do I need any special tools?</h2>
                  <p>
                    Delicious Experiences are designed for home cooks with home cooking equipment.
                    With that said, some experiences and techniques require special tools,
                    especially when it comes to baking or pasta-making. We’re big believers in ‘no
                    surprises’, which is why each chef’s experience offering page notes exactly what
                    kitchen tools you’ll need. If you would like to do a workshop that requires
                    special tools, please leave yourself enough time to purchase anything required!
                  </p>
                </li>

                <li>
                  <h2>Can you accommodate food allergies/intolerances?</h2>
                  <p>
                    Delicious Experiences chefs create their own menus and offerings, so we cannot
                    guarantee that special accommodations can be granted across the board for every
                    listed experience. However, our chefs are glad to make menu adjustments based on
                    your unique needs and preferences with enough advance notice! One great
                    advantage of this setting is the safety of using your own kitchen, minimizing
                    chances of cross-contamination frequently encountered in restaurant settings.
                  </p>
                </li>

                <li>
                  <h2>In which languages are the experiences offered?</h2>
                  <p>
                    You can view the languages spoken on each of the culinary expert’s offering
                    page.
                  </p>
                </li>

                <li>
                  <h2>I have more questions. Who can help?</h2>
                  <p>
                    You can <a href="/contact">contact us</a> with any questions that you may have!
                    We are always happy to hear from you.
                  </p>
                </li>
              </ol>
            </article>
          </section>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FaqPage;
