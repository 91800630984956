import React from 'react';
import css from './SectionSeparator.css';

const SectionSeparator = props => {
  return (
    <div className={css.separator}>
      <svg
        preserveAspectRatio="none"
        width="100%"
        height="5"
        viewBox="0 0 820 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.27539 0.750264C3.30889 -0.216234 1.74189 -0.216234 0.775391 0.750264C0.420011 1.10564 0.195303 1.54221 0.101269 2.00027H0V3.0002H0.101255C0.195282 3.45827 0.419994 3.89487 0.775391 4.25026C1.74189 5.21676 3.30889 5.21676 4.27539 4.25026C4.63079 3.89487 4.8555 3.45827 4.94953 3.0002L820 3.00026V2.00033L4.94951 2.00027C4.85548 1.54221 4.63077 1.10564 4.27539 0.750264Z"
        />
      </svg>
    </div>
  );
};

export default SectionSeparator;
