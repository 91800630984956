import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AddImages.css';

const MoveImageButton = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.moveImage, className);
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className={classes} onClick={onClick}>
      ⬅
    </a>
  );
};

MoveImageButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

MoveImageButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default MoveImageButton;
