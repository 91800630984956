import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { BookingTimeForm } from '../../forms';
import { tz } from 'moment-timezone';
import css from './BookingPanelN.css';
import SvgLineArrow from '../SvgIcon/SvgLineArrow';
import BasicModal from '../Modal/BasicModal';
// This defines when ModalInMobile shows content as Modal
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    onFetchTimeSlots,
    monthlyTimeSlots,
    intl,
    topbarBelow,
    onSetBookingModal,
    bookingModalOpen,
  } = props;

  const price = listing.attributes.price;
  const listing_id = listing.id.uuid;
  const timeZone = tz.guess();
  const { formattedPrice } = priceData(price, intl);

  const classes = classNames(
    rootClassName || css.root,
    className,
    !topbarBelow && !bookingModalOpen && css.overlapHero
  );
  const isShowClasses = bookingModalOpen ? css.isActive : '';
  const rcClasses = classNames(css.rightColumn, isShowClasses);
  const mobileBtnClasses = classNames(css.mobileBtn, isShowClasses);
  const mSpaceTopClasses = classNames(css.mobileSpaceTop, isShowClasses);
  const mSpaceBottomClasses = classNames(css.mobileSpaceBottom, isShowClasses);
  const barClasses = classNames(css.barOuter, bookingModalOpen && css.isActive);

  const closeModalFn = () => onSetBookingModal(false);
  const modalComponent = !bookingModalOpen ? null : (
    <BasicModal className={css.basicModal} closeModalFn={closeModalFn}>
      <button className={css.modalCloseButton} onClick={closeModalFn}>
        <span>X</span>
      </button>
      <h2 className={css.modalTitle}>Book this experience</h2>
    </BasicModal>
  );

  return (
    <>
      {modalComponent}

      <div className={classes}>
        <div className={css.bar}>
          <div className={barClasses}>
            <div className={css.container}>
              <div className={css.priceTitle}></div>
              <a className={mobileBtnClasses} href={`/events?xpId=${listing_id}`}>
                <div className={css.mobileBtnText}>BOOK THIS EXPERIENCE</div>
              </a>
              <div aria-hidden="true" className={mSpaceTopClasses} />

              <div className={css.barAction}>
                <div className={css.buttonGroup}>
                  <a className={css.giftButton} href={`/events?xpId=${listing_id}`}>
                    <span>Book This Experience</span>
                    <SvgLineArrow />
                  </a>
                  <div className={css.or}>or</div>
                  <a className={css.giftButton} href={`/events?xpId=${listing_id}`}>
                    <span className={css.giftButtonText}>Find out more</span>
                    <SvgLineArrow />
                  </a>
                </div>
              </div>

              <div className={css.columnWrapper}>
                <div className={rcClasses}>
                  {!bookingModalOpen ? null : (
                    <BookingTimeForm
                      className={css.bookingForm}
                      formId="BookingPanel"
                      submitButtonWrapperClassName={css.submitButtonWrapper}
                      unitType={unitType}
                      onSubmit={onSubmit}
                      price={price}
                      formattedPrice={formattedPrice}
                      isOwnListing={isOwnListing}
                      listingId={listing.id}
                      monthlyTimeSlots={monthlyTimeSlots}
                      onFetchTimeSlots={onFetchTimeSlots}
                      startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                      endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                      timeZone={timeZone}
                      giftButtonLink={`/gift/${listing_id}`}
                      bookingButtonLink={`/book/${listing_id}`}
                    />
                  )}
                </div>
              </div>
              <div aria-hidden="true" className={mSpaceBottomClasses} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  topbarBelow: false,
  bookingModalOpen: false,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  topbarBelow: bool,
  onSetBookingModal: func.isRequired,
  bookingModalOpen: bool,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
