import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL } from '../../util/types';
import {
  createSlug,
  getListingType,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';

import css from './UserNav.css';

const listingTab = (listing, selectedPageName) => {
  if (!listing) {
    return {
      text: 'Edit experience',
      selected: selectedPageName === 'NewListingPage',
      linkProps: {
        name: 'NewListingPage',
      },
    };
  }
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', state } = currentListing.attributes;
  const slug = createSlug(title);
  const isDraft = state === LISTING_STATE_DRAFT;

  return {
    text: 'Edit experience',
    selected: selectedPageName === 'EditListingPage',
    linkProps: {
      name: 'EditListingPage',
      params: {
        id,
        slug,
        type: getListingType(isDraft),
        tab: 'photos',
      },
    },
  };
};

const listingLink = listing => {
  if (!listing) return;

  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', state } = currentListing.attributes;
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = state === LISTING_STATE_DRAFT;
  const variant = isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : null;

  const linkProps = !!variant
    ? {
        name: 'ListingPageVariant',
        params: {
          id,
          slug,
          variant,
        },
      }
    : {
        name: 'ListingPage',
        params: { id, slug },
      };

  return {
    text: 'Preview experience',
    selected: false,
    linkProps,
  };
};

const UserNav = props => {
  const { className, rootClassName, selectedPageName, listing, canEditListing } = props;
  const classes = classNames(rootClassName || css.root, className);

  let tabs = [];

  if (canEditListing) {
    tabs.push({
      ...listingTab(listing, selectedPageName),
    });
  }

  tabs.push({
    text: <FormattedMessage id="UserNav.profileSettingsPage" />,
    selected: selectedPageName === 'ProfileSettingsPage',
    disabled: false,
    linkProps: {
      name: 'ProfileSettingsPage',
    },
  });
  tabs.push({
    text: <FormattedMessage id="UserNav.contactDetailsPage" />,
    selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
    disabled: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  });

  const link = listingLink(listing);
  if (link) tabs.push({ ...link });

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  canEditListing: false,
};

const { string, bool } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
  canEditListing: bool,
};

export default UserNav;
